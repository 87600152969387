body {
  background-color: black;
  color: #fff;
}

.text-stroke {
  -webkit-text-fill-color: black;
  -webkit-text-stroke: 1px white;
}

.bg-btn-gradient {
  background: linear-gradient(270deg, #ffff05 0%, rgba(217, 217, 217, 0) 100%);
}

.box-shadow-custom {
  box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.25);
}

.bg-box {
  background: linear-gradient(black, black) padding-box,
    linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5))
      border-box;
  border: 1px solid transparent;
}

.bg-box-hover {
  background: linear-gradient(black, black) padding-box,
    linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffff05) border-box;
  border: 1px solid transparent;
}

.arrow-animate {
  animation: bounce 1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.rocket-animate {
  animation: bounce2 4s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.button-animate {
  transition: all 0.3s linear;
  width: 64px;
  animation: button-animate 3s alternate infinite;
}

@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-10px, 0, 0);
  }
}

@keyframes bounce2 {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-50px, 50px, 20px);
  }
}

@keyframes button-animate {
  from {
    width: 64px;
  }
  to {
    width: 96px;
  }
}
