@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

ul,
ol {
  list-style: none;
}

a {
  cursor: pointer;
  color: inherit;
}

img {
  max-width: 100%;
}

input {
  outline: none;
}

html {
  scroll-behavior: smooth;
}

.font-mont {
  font-family: "Montserrat", sans-serif;
}
